import PublitioAPI from "publitio_js_sdk";
import {
  errorNotification,
  successNotification,
} from "../../../components/Notifications";
import request from "../../../requests/request";
import {
  getMotivosMovimientosCaja,
  postMotivoMovCaja,
  readComprobantes,
  readMedioPago,
} from "../../../requests/urls";
import { api_key, api_secret } from "../../../utils/publit.io";

export default async function putConfiguracionGeneral(
  data,
  isTipoVenta,
  notification = true,
) {
  let result = null;
  try {
    const response = await request({
      method: "PUT",
      url: "/api/sucursal/put-config-general/1/",
      data: isTipoVenta
        ? data
        : {
            ...data,
            tipo_venta: Array.isArray(data.tipo_venta)
              ? data.tipo_venta.map((d) => d.id)[0]
              : data.tipo_venta,
            json_leyendas: data.json_leyendas
              ? JSON.stringify(data.json_leyendas)
              : null,
            leyendas: data.activar_leyenda ? true : false,
          },
    });
    if (response.status === 201) {
      result = response.data;
      notification && successNotification("Cambios guardados con éxito!");
    } else {
      errorNotification(
        "Ha surgido un error y los cambios no se han logrado guardar",
      );
    }
  } catch (error) {
    errorNotification(
      "Ha surgido un error y los cambios no se han logrado guardar",
    );
    console.error(error);
  }
  return result;
}

export async function getTiposComprobante() {
  let result = [];
  try {
    const response = await request({
      method: "GET",
      url: readComprobantes,
    });
    result = response && response.data;
  } catch (error) {
    console.error(error);
  }
  return result;
}

export async function getMotivosCaja(tipo, all) {
  //1=entrada 0=salida
  let result = [];
  try {
    const response = await request({
      method: "GET",
      url: getMotivosMovimientosCaja({ tipo, all }),
    });
    result = response && response.data;
  } catch (error) {
    console.error(error);
  }
  return result;
}

export async function postMotivoCaja(form) {
  let result = 500;
  try {
    const response = await request({
      method: "GET",
      url: postMotivoMovCaja,
      body: form,
    });
    result = response.status;
  } catch (error) {
    console.error(error);
  }
  return result;
}

export async function getMediosPago() {
  let result = [];
  try {
    const response = await request({
      method: "GET",
      url: readMedioPago,
    });
    result = response && response.data;
  } catch (error) {
    console.error(error);
  }
  return result;
}

export async function postUploadLogo(image) {
  let result = null;
  const publitio = new PublitioAPI(api_key, api_secret);
  await publitio
    .uploadFile(image, "file")
    .then((data) => {
      if (data) result = data;
    })
    .catch((error) => {
      console.log(error);
    });
  return result;
}

export async function deleteLogo(id_image) {
  let result = null;
  const publitio = new PublitioAPI(api_key, api_secret);
  await publitio
    .call(`/files/delete/${id_image}`, "DELETE")
    .then((data) => {
      if (data) result = data;
    })
    .catch((error) => {
      console.log(error);
    });
  return result;
}

export const column = [
  { id: "articulo", nombre: "Artículo", isActive: true },
  { id: "original", nombre: "Original", isActive: true },
  { id: "descripcion", nombre: "Descripción", isActive: true },
  { id: "precioCosto", nombre: "Precio Costo", isActive: false },
  { id: "precioLista", nombre: "Precio Lista", isActive: true },
  { id: "precioVenta", nombre: "Precio Venta", isActive: true },
  { id: "stock", nombre: "Stock", isActive: true },
  { id: "proveedor", nombre: "Proveedor", isActive: true },
];
