import classNames from "classnames";
import React from "react";
import { Col } from "shards-react";
import SidebarMainNavbar from "./SidebarMainNavbar";
import SidebarNavItems from "./SidebarNavItems";
import { useGeneralConfigStore } from "../../../stores";

export default function MainSidebar() {
  const { sidebarOpen } = useGeneralConfigStore();

  const classes = classNames("main-sidebar", "px-0", "col-12", {
    open: sidebarOpen,
  });

  return (
    <Col tag="aside" className={classes} lg={{ size: 2 }} md={{ size: 3 }}>
      <SidebarMainNavbar />
      <SidebarNavItems />
    </Col>
  );
}
