import { Box } from "@material-ui/core";
import React from "react";
import { Nav } from "shards-react";
import { MODULOS } from "../../../../constantes/modulos";
import { useModulosContext } from "../../../../context/ModulosContext";
import "../../../../styles.css";
import Carrito from "./Carrito";
import UserActions from "./UserActions";

const NavbarNav = (props) => {
  const { activo: moduloVentasActivo } = useModulosContext().tieneModuloActivo(
    MODULOS.VENTAS,
  );

  return (
    <div>
      <Nav
        style={{ justifyContent: "space-between", alignItems: "center" }}
        navbar
        className="flex-row d-flex"
      >
        {moduloVentasActivo ? (
          <Carrito history={props.history} />
        ) : (
          <Box width="100%" />
        )}
        <div className="d-inline-flex">
          <UserActions history={props.history} />
        </div>
      </Nav>
    </div>
  );
};

export default NavbarNav;
