import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ReactDOM from "react-dom";

import routes from "./routes";
import withTracker from "./withTracker";

import "./styles.css";
import "semantic-ui-css/semantic.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/shards-dashboards.1.1.0.min.css";

import Login from "./views/Login";
import { connect } from "react-redux";
import {
  getPerfilUsuario,
  setTokenToState,
  setSesionByToken,
} from "./Redux/Actions";
import Errors from "./views/Errors";
import ReactNotification from "react-notifications-component";
import { isVendedor } from "./data/perfil";
import { getConfigGeneral } from "./Redux/Actions/actionConfigGeneral";
import request from "./requests/request";
import { MeliContextProvider } from "./context/MeliContext";
import { activateLogRocket } from "./services/logRocket";
import { useGeneralConfigStore } from "./stores";

const mapStateToProps = (state) => ({
  tokenRedux: state.loginReducer.token,
  perfil: state.loginReducer.perfil,
  meli_integracion: state.configGeneral.configGeneral.meli_integracion,
  woocommerce_integracion:
    state.configGeneral.configGeneral.woocommerce_integracion,
  correctSession: state.loginReducer.correctSession,
  integracion_afip: state.configGeneral.configGeneral.integracion_afip,
});

const mapDispatchToProps = (diaptch) => {
  return {
    isAuth: (value) => diaptch(setTokenToState(value)),
    // setDefaultRedondeo: () => diaptch(setDefaultRedondeo()),
    // setDefaultStockReserva: () => diaptch(setDefaultStockReserva()),
    // setDefaultDiasVigencia: () => diaptch(setDefaultDiasVigencia()),
    setConfiguracionGeneral: () => diaptch(getConfigGeneral()),
    setPerfil: () => diaptch(getPerfilUsuario()),
    setSesionByToken: (value) => diaptch(setSesionByToken(value)),
  };
};

class App extends Component {
  constructor() {
    super();
    this.state = {
      enabled_logRocket: false,
    };
  }

  async componentDidMount() {
    // this.props.setDefaultRedondeo();
    // this.props.setDefaultStockReserva();
    // this.props.setDefaultDiasVigencia();

    //Obtenemos el token almacenado en local storage
    const tokenLocal = localStorage.getItem("token");
    //Si existe el token en local storage, lo seteamos en redux
    if (tokenLocal != null) {
      //Funcion que setea el token en redux
      this.props.isAuth(tokenLocal);
      this.getSesionByToken();
    } else {
      this.props.setSesionByToken(true);
    }
    const { updateConfig } = useGeneralConfigStore.getState();
    try {
      await updateConfig();
    } catch (error) {
      console.error("Error al actualizar la configuración:", error);
    }
  }

  getSesionByToken = async () => {
    let value = false;
    try {
      const response = await request({
        method: "GET",
        url: "/api/usuario/get-sesion-token/",
      });
      if (response.status === 200) {
        value = response.data;
      } else {
        value = false;
      }
    } catch (error) {
      //Si da error value quedaria en false
      console.error(error);
    }
    this.props.setSesionByToken(value);
  };

  render() {
    const tokenLocal = localStorage.getItem("token");
    const { tokenRedux, correctSession, perfil } = this.props;
    tokenLocal &&
      tokenRedux &&
      correctSession &&
      this.props.setConfiguracionGeneral();
    tokenLocal && tokenRedux && correctSession && this.props.setPerfil();
    /**Funcion encargada de detectar modificaciones en el local storage
     * realizadas por el usuario del navegador, en caso de detectar un modificacion
     * borra todo el local storage.
     */
    // window.addEventListener("storage", function(e) {
    //   localStorage.removeItem("token");
    //   localStorage.removeItem("empleado");
    //   window.location.reload("/");
    // })

    if (perfil && correctSession && !this.state.enabled_logRocket) {
      activateLogRocket(perfil, this.state.enabled_logRocket).then(
        (response) => {
          if (response) {
            this.setState({
              enabled_logRocket: true,
            });
          }
        },
      );
    }

    const handleLogin = (tokenRedux) => {
      //Si no hay token en el navegador o en el redux o la sesion es incorrecta, redireccionamos al login
      if (tokenRedux === null || tokenLocal === null || !correctSession) {
        return <Login />;
      } else {
        return (
          <React.Fragment>
            <MeliContextProvider>
              <Router basename={process.env.REACT_APP_BASENAME || ""}>
                <div>
                  {ReactDOM.createPortal(
                    <ReactNotification />,
                    document.getElementById("notificaciones"),
                  )}
                  <Switch>
                    {routes(
                      isVendedor(this.props.perfil),
                      this.props.meli_integracion,
                      this.props.woocommerce_integracion,
                      this.props.integracion_afip,
                    ).map((route, index) => {
                      return (
                        <Route
                          key={index}
                          path={route.path}
                          exact={route.exact}
                          component={withTracker((props) => {
                            return (
                              <route.layout {...props}>
                                <route.component {...props} />
                              </route.layout>
                            );
                          })}
                        />
                      );
                    })}
                    <Route component={Errors} />
                  </Switch>
                </div>
              </Router>
            </MeliContextProvider>
          </React.Fragment>
        );
      }
    };
    return <div>{handleLogin(tokenRedux)}</div>;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
