import axios from "axios";

//Defino la promesa en la función request()...
const request = async ({
  method,
  url,
  data,
  params,
  headers,
  signal,
  ...props
}) => {
  const token = localStorage.getItem("token");

  const defaultHeaders = { Authorization: `Token ${token}` };

  const config = {
    method,
    url,
    data,
    params,
    headers: { ...defaultHeaders, ...headers },
    signal,
    ...props,
  };

  console.log(config, "config");

  try {
    const response = await axios(config);
    return response;
  } catch (err) {
    if (axios.isCancel(err)) {
      console.error("Solicitud cancelada:", err.message);
    } else {
      const { response } = err;
      const { status, data } = response || {};

      console.error(
        `Error ${status ? `HTTP ${status}` : "de red"}:${data ? data.detail : err.message}`,
      );
    }
  }
};

export default request;
