import React from "react";
import { Grid, TextField, MenuItem, FormControl } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useSearchEmpleado } from "../../../../customHooks/useSearchEmpleado";
import { useSearchMotivosBajaRetencion } from "../../../../customHooks/useSearchMotivosBajaRetencion";

export default function FormularioCertificado({
  values,
  handleBlur,
  setFieldValue,
  errors,
}) {
  const {
    useEmpleado: { empleados },
    useQueryEmpleado,
  } = useSearchEmpleado();
  const { motivos } = useSearchMotivosBajaRetencion();

  const handeleChangeMotivo = (event) => {
    setFieldValue("bajaCertificado.motivo_baja", event.target.value);
  };

  const changeDataCertificado = (e) => {
    const { name, value } = e.target;
    setFieldValue(`certificado.${name}`, value);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TextField
          name="motivo_baja"
          style={{ backgroundColor: "white" }}
          select
          size="small"
          fullWidth
          label="Motivo de desactivación"
          value={values.bajaCertificado.motivo_baja || ""}
          onChange={handeleChangeMotivo}
          variant="outlined"
          error={errors.bajaCertificado && errors.bajaCertificado.motivo_baja}
          helperText={
            errors.bajaCertificado && errors.bajaCertificado.motivo_baja
          }
        >
          {motivos.map((motivo, index) => (
            <MenuItem key={index} index={index} value={motivo}>
              {motivo.nombre}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          options={empleados}
          fullWidth={true}
          filterOptions={(options) => options}
          getOptionLabel={(empleado) =>
            empleado.nombre + " " + empleado.apellido
          }
          value={values.bajaCertificado.responsable_baja}
          onBlur={handleBlur}
          inputValue={useQueryEmpleado.query || ""}
          onInputChange={(_event, value) => {
            useQueryEmpleado.setQuery(value);
          }}
          onChange={(_event, value) =>
            setFieldValue("bajaCertificado.responsable_baja", value)
          }
          size="small"
          renderInput={(params) => (
            <TextField
              {...params}
              label="Responsable"
              placeholder="Responsable"
              variant="outlined"
              fullWidth={true}
              error={
                errors.bajaCertificado &&
                errors.bajaCertificado.responsable_baja
              }
              helperText={
                errors.bajaCertificado &&
                errors.bajaCertificado.responsable_baja
              }
            />
          )}
          name="responsable_baja"
        />
      </Grid>
      {values.bajaCertificado.motivo_baja &&
        values.bajaCertificado.motivo_baja.nombre ===
          "Tengo certificado de exclusión" && (
          <>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Número de certificado"
                name="nroCertificado"
                variant="outlined"
                size="small"
                value={values.certificado.nroCertificado || ""}
                onChange={changeDataCertificado}
                error={errors.certificado && errors.certificado.nroCertificado}
                helperText={
                  errors.certificado && errors.certificado.nroCertificado
                }
              />
            </Grid>
            <Grid item xs={6}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <FormControl fullWidth>
                  <TextField
                    name="fechaInicio"
                    value={values.certificado.fechaInicio || ""}
                    onChange={changeDataCertificado}
                    error={errors.certificado && errors.certificado.fechaInicio}
                    type={"date"}
                    label="Fecha de inicio de vigencia"
                    size="small"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  {errors.certificado && errors.certificado.fechaInicio && (
                    <span
                      style={{
                        color: "#f44336",
                        display: "inline-bloc",
                        fontSize: "80%",
                        lineHeight: "15px",
                        margin: "6px 0 0",
                      }}
                    >
                      {errors.certificado.fechaInicio}
                    </span>
                  )}
                </FormControl>
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={6}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <FormControl fullWidth>
                  <TextField
                    name="fechaFin"
                    value={values.certificado.fechaFin || ""}
                    onChange={changeDataCertificado}
                    error={errors.certificado && errors.certificado.fechaFin}
                    type={"date"}
                    label="Fecha fin de vigencia"
                    size="small"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  {errors.certificado && errors.certificado.fechaFin && (
                    <span
                      style={{
                        color: "#f44336",
                        display: "inline-bloc",
                        fontSize: "80%",
                        lineHeight: "15px",
                        margin: "6px 0 0",
                      }}
                    >
                      {errors.certificado.fechaFin}
                    </span>
                  )}
                </FormControl>
              </MuiPickersUtilsProvider>
            </Grid>
          </>
        )}
    </Grid>
  );
}
