import { openDB } from "idb";

const dbName = "boxer";

const dbVersion = 1;

export async function openDatabase() {
  const db = await openDB(dbName, dbVersion, {
    upgrade(db) {
      const tables = ["general_configs"];

      for (const x of tables) {
        if (!db.objectStoreNames.contains(x)) {
          db.createObjectStore(x, { keyPath: "id" });
        }
      }
    },
  });

  return db;
}
