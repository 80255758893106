import axios from "axios";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "shards-react";
import MainNavbar from "../components/layout/MainNavbar/MainNavbar";
import MainSidebar from "../components/layout/MainSidebar/MainSidebar";
import { ModulosContextProvider } from "../context/ModulosContext";
import { useGeneralConfigStore } from "../stores";

export default function DefaultLayout({ children, noNavbar, location }) {
  const [modalVerificacionToken, setModalVerificacionToken] = useState(false);

  const { sidebar_menu_config } = useGeneralConfigStore(
    (state) => state.config,
  );

  // Valida el token al cargar
  useEffect(() => {
    const validarToken = async () => {
      const token = localStorage.getItem("token");
      try {
        const res = await axios.get(`/api/usuario/validateToken/${token}`);
        if (!res.data) {
          setModalVerificacionToken(true);
          localStorage.clear();
        }
      } catch (error) {
        console.error(error);
      }
    };

    validarToken();
  }, []);

  const aceptarValidacion = () => {
    setModalVerificacionToken(false);
    localStorage.clear();
  };

  return (
    <ModulosContextProvider>
      <div>
        <Modal
          className="modalValidarToken"
          open={modalVerificacionToken}
          toggle={() => setModalVerificacionToken(!modalVerificacionToken)}
        >
          <ModalHeader>
            <h2>¡Atención!</h2>
          </ModalHeader>
          <ModalBody>
            <p className="text-center">
              Su cuenta fue iniciada en otro dispositivo.
            </p>
            <p className="text-center">
              Para continuar, presione Aceptar para volver a iniciar sesión.
            </p>
          </ModalBody>
          <ModalFooter>
            <Button theme="success" onClick={aceptarValidacion}>
              Aceptar
            </Button>
          </ModalFooter>
        </Modal>

        <Container
          fluid
          className={
            sidebar_menu_config ? "icon-sidebar-nav" : "icon-sidebar-nav-fixed"
          }
        >
          <Row>
            <MainSidebar />
            <Col className="main-content col" tag="main">
              {!noNavbar && <MainNavbar location={location} />}
              {children}
            </Col>
          </Row>
        </Container>
      </div>
    </ModulosContextProvider>
  );
}

DefaultLayout.propTypes = {
  noNavbar: PropTypes.bool,
};

DefaultLayout.defaultProps = {
  noNavbar: false,
};
