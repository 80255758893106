import React, { useEffect, useState } from "react";
import {
  Grid,
  InputAdornment,
  IconButton,
  TextField,
  MenuItem,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";
import validateMaxLength from "../../../utils/validateMaxLength";
import TooltipWithoutIcon from "../../../components/ait-reusable/TooltipMoreInfo/TooltipWithoutIcon";
import { CardIva } from "./CardIva";

const ListaPrecioItem = ({ lp, cambioDefault, setValue }) => {
  const cambioListaPrecio = (event) => {
    const { value, name } = event.target;
    lp[name] = value;
    setValue(lp);
  };

  const cambioBooleanIva = (event) => {
    const { value } = event.target;
    lp["already_iva"] = value === "true";
    setValue(lp);
  };

  const deactivateLista = () => {
    lp["is_active"] = false;
    setValue(lp);
  };

  return (
    <div>
      <Grid
        container
        style={{
          textAlign: "center",
          paddingLeft: 10,
          paddingRight: 20,
          paddingBottom: 10,
        }}
        spacing={2}
      >
        <Grid item sm={1} hidden={!lp.is_precio_lista}>
          <MenuItem
            onClick={() => {
              deactivateLista();
            }}
          >
            <i className="material-icons iconStockEdit" title="Dar de baja">
              delete
            </i>
          </MenuItem>
        </Grid>

        <Grid item sm={3} hidden={!lp.is_precio_lista}>
          <TextField
            onInput={validateMaxLength}
            name={"nombre"}
            style={{ backgroundColor: "white" }}
            fullWidth
            label="Nombre Lista de Precios"
            size="small"
            value={lp.nombre || ""}
            onChange={cambioListaPrecio}
            variant="outlined"
          />
        </Grid>

        <Grid item sm={2} hidden={!lp.is_precio_lista}>
          <TextField
            onInput={validateMaxLength}
            name={"descuento"}
            style={{ backgroundColor: "white" }}
            fullWidth
            size="small"
            label="Porcentaje descuento"
            value={lp.descuento || ""}
            onChange={cambioListaPrecio}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <TooltipWithoutIcon
                    body={
                      <h6
                        style={{ fontSize: 15, marginBottom: 0, marginTop: 0 }}
                      >
                        {"Representa el descuento sobre el precio mayorista que el proveedor le otorga,  " +
                          "por ejemplo: 0.15"}
                      </h6>
                    }
                    placement={"bottom"}
                  >
                    <IconButton aria-label="delete">
                      <HelpIcon />
                    </IconButton>
                  </TooltipWithoutIcon>
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item sm={4} hidden={lp.is_precio_lista}>
          <TextField
            onInput={validateMaxLength}
            name={"recargo_lista"}
            style={{ backgroundColor: "white" }}
            fullWidth
            size="small"
            label="Margen recargo mayorista"
            value={lp.recargo_lista || ""}
            onChange={cambioListaPrecio}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <TooltipWithoutIcon
                    body={
                      <h6
                        style={{ fontSize: 15, marginBottom: 0, marginTop: 0 }}
                      >
                        {"Representa la ganancia deseada de los artículos de este proveedor, " +
                          "por ejemplo: 0.415"}
                      </h6>
                    }
                    placement={"bottom"}
                  >
                    <IconButton aria-label="delete">
                      <HelpIcon />
                    </IconButton>
                  </TooltipWithoutIcon>
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item sm={lp.is_precio_lista ? 2 : 4}>
          <TextField
            onInput={validateMaxLength}
            name={"recargo_contado"}
            style={{ backgroundColor: "white" }}
            fullWidth
            label="Margen recargo contado"
            size="small"
            value={lp.recargo_contado || ""}
            onChange={cambioListaPrecio}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <TooltipWithoutIcon
                    body={
                      <h6
                        style={{ fontSize: 15, marginBottom: 0, marginTop: 0 }}
                      >
                        {"Representa la ganancia sobre el precio mayorista que el proveedor le otorga,  " +
                          "por ejemplo: 0.15"}
                      </h6>
                    }
                    placement={"bottom"}
                  >
                    <IconButton aria-label="delete">
                      <HelpIcon />
                    </IconButton>
                  </TooltipWithoutIcon>
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item sm={1} hidden={!lp.is_precio_lista}>
          <RadioGroup
            value={lp.es_default || false}
            onChange={() => cambioDefault(lp.id)}
          >
            <FormControlLabel
              value={true}
              control={<Radio color={"primary"} />}
              label={"Principal"}
            />
          </RadioGroup>
        </Grid>

        <Grid item sm={lp.is_precio_lista ? 3 : 4}>
          <CardIva
            values={lp}
            handleChange={cambioListaPrecio}
            setFieldValue={cambioBooleanIva}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default function CamposAdicionales({ values, setFieldValue }) {
  const [listaUnica, setListaUnica] = useState([]);

  useEffect(() => {
    if (!values.is_precio_lista) {
      let element = values.parametros.find(
        (i) => i.is_precio_lista === values.is_precio_lista,
      );
      if (element) {
        setListaUnica([element]);
      }
    } else {
      setListaUnica(
        values.parametros.filter(
          (i) => i.is_precio_lista === values.is_precio_lista,
        ),
      );
    }
  }, [values]);

  const setValue = (lp) => {
    let aux_list = [...values.parametros];
    const index = aux_list.findIndex((i) => i.id === lp.id);
    if (lp.id >= 0 || lp.is_active) {
      aux_list.splice(index, 1, lp);
    } else {
      aux_list.splice(index, 1);
    }
    setFieldValue("parametros", aux_list);
  };

  const cambioDefault = (id) => {
    let aux_list = [...values.parametros];
    aux_list.map((lp) => {
      if (lp.id === id) {
        lp["es_default"] = true;
      } else if (lp["is_precio_lista"]) {
        lp["es_default"] = false;
      }
    });
    setFieldValue("parametros", aux_list);
  };

  return (
    <>
      {listaUnica.map((lp) => {
        if (lp.is_precio_lista === values.is_precio_lista && lp.is_active) {
          return (
            <ListaPrecioItem
              key={lp.id}
              lp={lp}
              cambioDefault={cambioDefault}
              setValue={setValue}
            />
          );
        }
      })}
    </>
  );
}
