import { Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getConfigGeneral } from "../../../Redux/Actions/actionConfigGeneral";
import CardConfig from "./Components/CardConfig";
import Items from "./itemsConfig";

export default function General() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getConfigGeneral());
  }, []);

  return (
    <Grid container spacing={2}>
      {Items().map((item, index) => (
        <Grid key={index} item xs={12} md={6} lg={4}>
          <CardConfig item={item} />
        </Grid>
      ))}
    </Grid>
  );
}
