import React from "react";
import { Navbar, NavbarBrand } from "shards-react";
import { useGeneralConfigStore } from "../../../stores";

export default function SidebarMainNavbar() {
  const { setSidebarOpen } = useGeneralConfigStore();

  return (
    <div className="main-navbar">
      <Navbar
        className={
          "align-items-stretch bg-white flex-md-nowrap border-bottom p-0"
        }
        type="light"
      >
        <NavbarBrand
          className="w-100 mr-0"
          href="#"
          style={{ lineHeight: "35px" }}
        >
          <div
            className="m-auto"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              id="main-logo"
              className="d-inline-block align-top"
              style={{ maxWidth: "52%" }}
              src={require("../../../images/logo.svg")}
              alt="Shards Dashboard"
            />
          </div>
        </NavbarBrand>

        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          className="toggle-sidebar d-sm-inline d-md-none d-lg-none"
          onClick={() => setSidebarOpen(false)}
        >
          <i className="material-icons">&#xE5C4;</i>
        </a>
      </Navbar>
    </div>
  );
}

// import React from "react";
// import PropTypes from "prop-types";
// import { Navbar, NavbarBrand } from "shards-react";

// import { Dispatcher, Constants } from "../../../flux";

// class SidebarMainNavbar extends React.Component {
//   constructor(props) {
//     super(props);

//     this.handleToggleSidebar = this.handleToggleSidebar.bind(this);
//   }

//   handleToggleSidebar() {
//     Dispatcher.dispatch({
//       actionType: Constants.TOGGLE_SIDEBAR,
//     });
//   }

//   render() {
//     return (
//       <div className="main-navbar">
//         <Navbar
//           className="align-items-stretch bg-white flex-md-nowrap border-bottom p-0"
//           type="light"
//         >
//           <NavbarBrand
//             className="w-100 mr-0"
//             href="#"
//             style={{ lineHeight: "35px" }}
//           >
//             <div
//               className="m-auto"
//               style={{
//                 display: "flex",
//                 justifyContent: "center",
//                 alignItems: "center",
//               }}
//             >
//               <img
//                 id="main-logo"
//                 className="d-inline-block align-top"
//                 style={{ maxWidth: "52%" }}
//                 src={require("../../../images/logo.svg")}
//                 alt="Shards Dashboard"
//               />
//             </div>
//           </NavbarBrand>

//           {/* eslint-disable-next-line */}
//           <a
//             className="toggle-sidebar d-sm-inline d-md-none d-lg-none"
//             onClick={this.handleToggleSidebar}
//           >
//             <i className="material-icons">&#xE5C4;</i>
//           </a>
//         </Navbar>
//       </div>
//     );
//   }
// }

// SidebarMainNavbar.propTypes = {
//   hideLogoText: PropTypes.bool,
// };

// SidebarMainNavbar.defaultProps = {
//   hideLogoText: false,
// };

// export default SidebarMainNavbar;
