import React from "react";
import { Grid, FormControlLabel, Switch, Box, Chip } from "@material-ui/core";
import CertificadosCargados from "./CertificadosCargados";
import { AlertReusable } from "../../../../components/ait-reusable/Alerts/AlertReusable";
import FormularioCertificado from "./FormularioCertificado";
import InfoIcon from "@material-ui/icons/Info";

export default function Retenciones({
  values,
  handleChange,
  handleBlur,
  setFieldValue,
  touched,
  errors,
}) {
  const handleChangeCalculoRetenciones = (e) => {
    //Se debe validar que si existe un certificado nuevo cargado y se activa el calculo de retencion, se debe eliminar el certificado nuevo
    if (
      values.nuevos_certificados &&
      values.nuevos_certificados.length > 0 &&
      e.target.checked
    ) {
      setFieldValue("nuevos_certificados", []);
    }
    handleChange(e);
  };

  const tieneCertificadoVigente = () => {
    if (values.data_baja_certificado) {
      //Si existen bajas, se busca si existe alguna que no este anulada
      let bajaNoAnulada = values.data_baja_certificado.filter(
        (b) => b.fecha_anulacion === null,
      )[0];
      return bajaNoAnulada ? true : false;
    } else {
      return false;
    }
  };

  return (
    <Grid container spacing={2} style={{ marginTop: 10 }}>
      <Grid item sm={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <AlertReusable
              severity={"info"}
              text={
                "En este apartado se podra activar o desactivar el cálculo de las retenciones en los pagos como asi también obtener información de los certificados de retención."
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={values.calcular_retencion || false}
                  onChange={(e) => handleChangeCalculoRetenciones(e)}
                  name="calcular_retencion"
                />
              }
              label={
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  alignContent={"center"}
                  gridGap={4}
                >
                  Calcular retenciones{" "}
                </Box>
              }
            />
          </Grid>
          {tieneCertificadoVigente() && (
            <Grid item xs={12}>
              <Chip
                color="primary"
                icon={<InfoIcon />}
                label={
                  "Actualmente se está utilizando un certificado de exclusión, si activa el cálculo de retención se anulará dicho certificado."
                }
                style={{
                  marginBottom: 3,
                }}
              />
            </Grid>
          )}
          {!values.calcular_retencion && values.retencionActiva && (
            <Grid item xs={12}>
              <FormularioCertificado
                values={values}
                handleChange={handleChange}
                handleBlur={handleBlur}
                setFieldValue={setFieldValue}
                touched={touched}
                errors={errors}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item sm={12} style={{ borderTop: "solid 2px #cdced0" }}>
        <CertificadosCargados
          dataBaja={values.data_baja_certificado}
          retencionActiva={values.retencionActiva}
          setFieldValue={setFieldValue}
          values={values}
        />
      </Grid>
    </Grid>
  );
}
