import create from "zustand";
import request from "../requests/request";
import { get_ConfigGeneral, put_ConfigGeneral } from "../requests/urls";
import { openDatabase } from "./idb";

const CONFIG_STORE = "general_configs";

export const useGeneralConfigStore = create((set) => ({
  /**
   * Estado inicial del sidebar mobile. Determina si está abierto o cerrado.
   *
   * @type {boolean}
   */
  sidebarOpen: false,

  /**
   * Establece el estado del sidebar mobile.
   *
   * @param {boolean} isOpen - Si es true, el sidebar se abrirá; si es false, se cerrará.
   */
  setSidebarOpen: (isOpen) => set({ sidebarOpen: isOpen }),

  /**
   * Configuración general de la aplicación en memoria.
   *
   * @type {Object}
   */
  config: {},

  /**
   * Establece una nueva configuración en memoria.
   *
   * @param {Object} newConfig - La nueva configuración.
   * @returns {void}
   */
  setConfig: (newConfig) => set({ config: newConfig }),

  /**
   * Actualiza la configuración general desde el servidor y la guarda
   * en IndexedDB y el estado en memoria.
   *
   * @async
   * @returns {Promise<void>} Resuelve cuando la configuración ha sido actualizada.
   * @throws {Error} Si ocurre un error al obtener la configuración desde el servidor.
   */
  updateConfig: async () => {
    try {
      const response = await request({
        method: "GET",
        url: get_ConfigGeneral,
      });

      const data = response.data;

      const db = await openDatabase();
      const tx = db.transaction(CONFIG_STORE, "readwrite");
      await tx
        .objectStore(CONFIG_STORE)
        .put({ ...data, change_url_img: false });

      set({ config: data });
    } catch (err) {
      console.error("Error al obtener la configuración:", err);
    }
  },

  /**
   * Obtiene una propiedad o un conjunto de propiedades de la configuración
   * general almacenada en IndexedDB.
   *
   * @async
   * @param {string|string[]} keys - Clave o array de claves para buscar en la configuración.
   * @returns {Promise<Object|null>} Un objeto con las propiedades solicitadas, o null si no existe.
   */
  getProperty: async (keys) => {
    const db = await openDatabase();
    const storedConfig = await db.get(CONFIG_STORE, 1);

    if (storedConfig) {
      if (Array.isArray(keys)) {
        return keys.reduce((acc, key) => {
          acc[key] = storedConfig[key];
          return acc;
        }, {});
      }
      return storedConfig[keys];
    }

    return null;
  },

  /**
   * Actualiza una o varias propiedades específicas de la configuración
   * general almacenada en IndexedDB y en el estado en memoria. Además
   * actualiza la configuración en el servidor si se indica syncWithServer como true.
   *
   * @async
   * @param {string|string[]} keys - Clave o array de claves a actualizar.
   * @param {Object|string|number} newValue - Nuevo valor o un objeto con los nuevos valores.
   * @param {boolean} [syncWithServer=false] - Sincronizar con el servidor después de actualizar.
   * @returns {Promise<Object|null>} La configuración actualizada o null si no existe.
   */
  updateProperty: async (keys, newValue, syncWithServer = false) => {
    const db = await openDatabase();
    const tx = db.transaction(CONFIG_STORE, "readwrite");
    const store = tx.objectStore(CONFIG_STORE);

    const storedConfig = await store.get(1);

    if (storedConfig) {
      if (Array.isArray(keys)) {
        keys.forEach((key) => {
          storedConfig[key] = newValue[key] || newValue;
        });
      } else {
        storedConfig[keys] = newValue;
      }

      await store.put(storedConfig);

      set({ config: storedConfig });

      // Sincroniza con el servidor si es necesario
      if (syncWithServer) {
        try {
          const response = await request({
            method: "PUT",
            url: put_ConfigGeneral,
            data: {
              tipo_venta: Array.isArray(storedConfig.tipo_venta)
                ? storedConfig.tipo_venta.map((d) => d.id)[0]
                : storedConfig.tipo_venta,
              json_leyendas: storedConfig.json_leyendas
                ? JSON.stringify(storedConfig.json_leyendas)
                : null,
              leyendas: storedConfig.activar_leyenda ? true : false,
            },
          });

          if (!response) {
            console.warn("Error al sincronizar con el servidor.");
          }
        } catch (err) {
          console.error("Error en la sincronización con el servidor:", err);
        }
      }

      return storedConfig;
    }

    return null;
  },

  /**
   * Obtiene la configuración general completa almacenada en IndexedDB.
   *
   * @async
   * @returns {Promise<Object|null>} La configuración general completa o null si no existe.
   */
  getAllConfig: async () => {
    const db = await openDatabase();
    const storedConfig = await db.get(CONFIG_STORE, 1);
    return storedConfig;
  },
}));
