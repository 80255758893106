import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { NavLink as RouteNavLink } from "react-router-dom";
import { NavItem, NavLink } from "shards-react";
import ModalSuscribirseAModulo from "../../ait-reusable/ModalSuscribirseAModulo/ModalSuscribirseAModulo";

export default function SidebarNavItem({ item }) {
  const [openModalModulo, setOpenModalModulo] = useState(false);

  return (
    <>
      <NavItem
        onClick={() => (!item.activo ? setOpenModalModulo(true) : {})}
        style={{ cursor: "pointer" }}
      >
        <NavLink tag={RouteNavLink} to={item.to} disabled={!item.activo}>
          {!item.activo && (
            <AttachMoneyIcon
              style={{
                marginRight: 6,
                fontSize: "1.125rem",
                backgroundColor: "#3BB143",
                color: "white",
                borderRadius: "50%",
              }}
            />
          )}
          {item.activo && item.htmlBefore && (
            <div
              className="d-inline-block item-icon-wrapper"
              dangerouslySetInnerHTML={{ __html: item.htmlBefore }}
            />
          )}
          {item.title && (
            <span style={{ opacity: !item.activo ? "0.5" : "1" }}>
              {item.title}
            </span>
          )}
          {item.htmlAfter && (
            <div
              className="d-inline-block item-icon-wrapper"
              dangerouslySetInnerHTML={{ __html: item.htmlAfter }}
            />
          )}
        </NavLink>
      </NavItem>
      {openModalModulo ? (
        <ModalSuscribirseAModulo
          videoUrl={item.video}
          open={openModalModulo}
          nombreDelModulo={item.title}
          handleClose={() => setOpenModalModulo(false)}
        />
      ) : null}
    </>
  );
}

SidebarNavItem.propTypes = {
  item: PropTypes.object,
};
