import { FormControlLabel, Switch } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import putConfiguracionGeneral from "../../utils";

export default function Autoseleccion({ config, getConfig }) {
  const [autoSelection, setAutoSelection] = useState(false);

  const handleChangeAutoSelection = async () => {
    setAutoSelection(!autoSelection);
    putConfiguracionGeneral({
      ...config,
      es_autoseleccionado: !autoSelection,
    }).then((res) => getConfig(res));
  };

  useEffect(() => {
    if (config) setAutoSelection(config.es_autoseleccionado);
  }, []);

  return (
    <FormControlLabel
      className="pl-4"
      control={
        <Switch
          checked={autoSelection}
          color="primary"
          onChange={handleChangeAutoSelection}
          name="autoSelection"
        />
      }
      label="Autoselección"
    />
  );
}
