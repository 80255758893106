import FeedbackIcon from "@material-ui/icons/Feedback";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Nav } from "shards-react";
import { useModulosContext } from "../../../context/ModulosContext";
import getSidebarNavItems from "../../../data/sidebar-nav-items";
import BasicCollapseExample from "./../../../toggle";
import SidebarNavItem from "./SidebarNavItem";

const SidebarNavItems = (props) => {
  const {
    perfil,
    empleado,
    integracion_afip,
    meli_integracion,
    woocommerce_integracion,
  } = props;

  const { modulos, tieneModuloActivo } = useModulosContext();
  const [navItems, setNavItems] = useState([]);

  useEffect(() => {
    const onChange = () => {
      setNavItems(
        getSidebarNavItems(
          perfil,
          meli_integracion,
          woocommerce_integracion,
          integracion_afip,
          empleado,
          tieneModuloActivo,
        ),
      );
    };

    onChange();

    return () => {
      // Cleanup on unmount
    };
  }, [
    perfil,
    meli_integracion,
    woocommerce_integracion,
    integracion_afip,
    empleado,
    modulos,
  ]);

  return (
    <div className="nav-wrapper" style={{ overflowY: "auto" }}>
      <Nav className="nav--no-borders flex-column" style={{ marginBottom: 10 }}>
        {navItems.map((item, idx) => {
          if (!item) return null;
          else if (item.subNavLink == null) {
            return <SidebarNavItem key={idx} item={item} />;
          } else {
            return (
              <BasicCollapseExample
                key={idx}
                data={item.subNavLink}
                item={item}
              />
            );
          }
        })}
      </Nav>

      <div
        style={{
          position: "absolute",
          bottom: 5,
          paddingLeft: 10,
          paddingRight: 10,
          left: 0,
          right: 0,
        }}
      >
        <a
          style={{
            color: "#1a62a7",
            fontWeight: 600,
            fontSize: 13,
            textDecoration: "none",
          }}
          href="https://forms.gle/he6GxK9cEYsLcxxL8"
          target="blank"
        >
          <FeedbackIcon
            style={{ paddingRight: 3, color: "#1a62a7", fontSize: 20 }}
          />
          <span className="span-sugerencias">Sugerencias</span>
        </a>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  perfil: state.loginReducer.perfil,
  meli_integracion: state.configGeneral.configGeneral.meli_integracion,
  woocommerce_integracion:
    state.configGeneral.configGeneral.woocommerce_integracion,
  integracion_afip: state.configGeneral.configGeneral.integracion_afip,
  empleado: state.loginReducer.empleado,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarNavItems);
