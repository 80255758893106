import MailIcon from "@material-ui/icons/Mail";
import PrintIcon from "@material-ui/icons/Print";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  config_general,
  getConfigGeneral,
} from "../../../Redux/Actions/actionConfigGeneral";
import AddLogo from "./Components/ItemsConfig/AddLogo";
import DatosCI from "./Components/ItemsConfig/DatosCI";
import Descuentos from "./Components/ItemsConfig/Descuentos";
import { LeyendasComprobantes } from "./Components/ItemsConfig/LeyendasComprobantes";
import TipoVenta from "./Components/ItemsConfig/TipoVenta";
// import SwitchCaja from "./Components/ItemsConfig/SwitchCaja";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import BarChartIcon from "@material-ui/icons/BarChart";
import BuildIcon from "@material-ui/icons/Build";
import CategoryIcon from "@material-ui/icons/Category";
import CommentIcon from "@material-ui/icons/Comment";
import ComputerIcon from "@material-ui/icons/Computer";
import DescriptionIcon from "@material-ui/icons/Description";
import EmojiEmotionsIcon from "@material-ui/icons/EmojiEmotions";
import EventBusyIcon from "@material-ui/icons/EventBusy";
import HeightIcon from "@material-ui/icons/Height";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import LockIcon from "@material-ui/icons/Lock";
import PublicIcon from "@material-ui/icons/Public";
import ReceiptIcon from "@material-ui/icons/Receipt";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import TouchAppIcon from "@material-ui/icons/TouchApp";
import TrendingDownIcon from "@material-ui/icons/TrendingDown";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import ViewQuiltIcon from "@material-ui/icons/ViewQuilt";
import ViewWeekIcon from "@material-ui/icons/ViewWeek";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import Autoseleccion from "./Components/ItemsConfig/AutoSeleccion";
import ClaveMaestra from "./Components/ItemsConfig/ClaveMaestra/ClaveMaestra";
import ClienteMeli from "./Components/ItemsConfig/ClienteMeli";
import CodigoPorDefecto from "./Components/ItemsConfig/CodigoPorDefecto";
import ColsTablaArticulos from "./Components/ItemsConfig/ColsTablaArticulos";
import ConfigPrecios from "./Components/ItemsConfig/ConfigPrecios";
import ConfigReservaStock from "./Components/ItemsConfig/ConfigReservaStock";
import ConfigSideBar from "./Components/ItemsConfig/ConfigSideBar";
import ConfigVigenciaPresup from "./Components/ItemsConfig/ConfigVigenciaPresup";
import DescripcionesArticulos from "./Components/ItemsConfig/DescripcionesArticulos";
import DiasAVencer from "./Components/ItemsConfig/DiasAVencer";
import FacturacionM from "./Components/ItemsConfig/FacturacionM";
import FacturadorAutomaticoMercadoLibre from "./Components/ItemsConfig/FacturadorAutomaticoMercadoLibre";
import FechaUltimaActualizacionArticulo from "./Components/ItemsConfig/FechaUltimaActualizacionArticulo";
import { InformacionComprobantesVentas } from "./Components/ItemsConfig/InformacionComprobantesVentas/InformacionComprobantesVentas";
import InformacionFCEM from "./Components/ItemsConfig/InformacionFCEM";
import { InformacionPresupuestos } from "./Components/ItemsConfig/InformacionPresupuestos/InformacionPresupuestos";
import { TitleInfoPresupuestos } from "./Components/ItemsConfig/InformacionPresupuestos/TitleInfoPresupuestos";
import LimitarVentasCCC from "./Components/ItemsConfig/LimitarVentasCCC";
import MailSucursal from "./Components/ItemsConfig/MailSucursal";
import MayoristaMinorista from "./Components/ItemsConfig/MayoristaMinorista";
import MedidasEtiqueta from "./Components/ItemsConfig/MedidasEtiqueta";
import MedioPagoDefault from "./Components/ItemsConfig/medioPagoDefault";
import MotivosCuentaCorriente from "./Components/ItemsConfig/MotivosCuentaCorriente";
import OrganizacionPantallaVentas from "./Components/ItemsConfig/OrganizacionPantallaVentas/OrganizacionPantallaVentas";
import Percepciones from "./Components/ItemsConfig/Percepciones";
import RecargosCtaCte from "./Components/ItemsConfig/RecargosCtaCte";
import SaltarPrevisualizacion from "./Components/ItemsConfig/SaltarPrevisualizacion";
import SeleccionarProvinciaSucursal from "./Components/ItemsConfig/SeleccionarProvinciaSucursal/SeleccionarProvinciaSucursal";
import TipoComprobanteDefault from "./Components/ItemsConfig/TipoComprobanteDefault";
import TipoImpresion from "./Components/ItemsConfig/tipoImpresion";
import TiposCliente from "./Components/ItemsConfig/TiposCliente/TiposCliente";
import Transporte from "./Components/ItemsConfig/Transporte";
import ValidacionPreciosArticulos from "./Components/ItemsConfig/ValidacionPreciosArticulos";
import WhatsappDefault from "./Components/ItemsConfig/WhatsappEnvioDefault";

export default function Items() {
  const { configGeneral } = useSelector((state) => state.configGeneral);
  const dispatch = useDispatch();

  const configuracionGeneral = (res) => {
    if (res !== null) dispatch(config_general(res));
    else dispatch(getConfigGeneral());
  };

  return [
    {
      title: "Precios",
      icon: <AttachMoneyIcon style={styles.icon} />,
      component: (
        <ConfigPrecios
          config={configGeneral}
          getConfig={configuracionGeneral}
        />
      ),
    },
    {
      title: "Medio de pago por defecto",
      icon: <DescriptionIcon style={styles.icon} />,
      component: (
        <MedioPagoDefault
          config={configGeneral}
          getConfig={configuracionGeneral}
        />
      ),
    },
    {
      title: "Factura Tipo M",
      icon: <DescriptionIcon style={styles.icon} />,
      component: (
        <FacturacionM config={configGeneral} getConfig={configuracionGeneral} />
      ),
    },
    {
      title: "Reservar stock",
      icon: <ViewQuiltIcon style={styles.icon} />,
      component: (
        <ConfigReservaStock
          config={configGeneral}
          getConfig={configuracionGeneral}
        />
      ),
    },
    {
      title: "Fecha vigencia de presupuesto",
      icon: <EventBusyIcon style={styles.icon} />,
      component: (
        <ConfigVigenciaPresup
          config={configGeneral}
          getConfig={configuracionGeneral}
        />
      ),
    },
    {
      title: "Logo de sucursal",
      icon: <AddPhotoAlternateIcon style={styles.icon} />,
      component: (
        <AddLogo config={configGeneral} getConfig={configuracionGeneral} />
      ),
    },
    {
      title: "Mail de sucursal",
      icon: <MailIcon style={styles.icon} />,
      component: (
        <MailSucursal config={configGeneral} getConfig={configuracionGeneral} />
      ),
    },
    {
      title: "Días a vencer",
      icon: <ReceiptIcon style={styles.icon} />,
      component: (
        <DiasAVencer config={configGeneral} getConfig={configuracionGeneral} />
      ),
    },
    {
      title: "Precio mayorista/minorista",
      icon: <LocalOfferIcon style={styles.icon} />,
      component: (
        <MayoristaMinorista
          config={configGeneral}
          getConfig={configuracionGeneral}
        />
      ),
    },
    {
      title: "Versión para imprimir",
      icon: <PrintIcon style={styles.icon} />,
      component: (
        <TipoImpresion
          config={configGeneral}
          getConfig={configuracionGeneral}
        />
      ),
    },
    {
      title: "Comprobante por defecto",
      icon: <DescriptionIcon style={styles.icon} />,
      component: (
        <TipoComprobanteDefault
          config={configGeneral}
          getConfig={configuracionGeneral}
        />
      ),
    },
    {
      title: "Código por defecto y ocultar código",
      icon: <BarChartIcon style={styles.icon} />,
      component: (
        <CodigoPorDefecto
          config={configGeneral}
          getConfig={configuracionGeneral}
        />
      ),
    },
    // {
    //   title: "Motivos de movimiento de caja",
    //   icon: <SwapVerticalCircleIcon style={styles.icon} />,
    //   component: (
    //     <AltaBajaMotivoMovCaja
    //       config={configGeneral}
    //       getConfig={configuracionGeneral}
    //     />
    //   ),
    // },
    // {
    //   title: "Motivos CTA CTE proveedores",
    //   icon: <LocalAtmIcon style={styles.icon} />,
    //   component: (
    //     <MotivosCuentaCorriente
    //       config={configGeneral}
    //       getConfig={configuracionGeneral}
    //     />
    //   ),
    // },
    {
      title: "Motivos CTA CTE proveedores",
      icon: <LocalAtmIcon style={styles.icon} />,
      component: (
        <MotivosCuentaCorriente
          config={configGeneral}
          getConfig={configuracionGeneral}
        />
      ),
    },
    {
      title: "Cliente Meli",
      icon: <EmojiEmotionsIcon style={styles.icon} />,
      component: (
        <ClienteMeli config={configGeneral} getConfig={configuracionGeneral} />
      ),
    },
    {
      title: "Descuentos",
      icon: <TrendingDownIcon style={styles.icon} />,
      component: (
        <Descuentos config={configGeneral} getConfig={configuracionGeneral} />
      ),
    },
    {
      title: "Descuentos por plazo",
      icon: <TrendingUpIcon style={styles.icon} />,
      component: (
        <RecargosCtaCte
          config={configGeneral}
          getConfig={configuracionGeneral}
        />
      ),
    },
    {
      title: "Datos en CI, Presupuestos, Recibos y Comprobantes",
      icon: <DescriptionIcon style={styles.icon} />,
      component: (
        <DatosCI config={configGeneral} getConfig={configuracionGeneral} />
      ),
    },
    {
      title: "Autoselección",
      icon: <TouchAppIcon style={styles.icon} />,
      component: (
        <Autoseleccion
          config={configGeneral}
          getConfig={configuracionGeneral}
        />
      ),
    },
    // {
    //   title: "Ocultar Código de Artículo",
    //   icon: <VisibilityOffIcon style={styles.icon} />,
    //   component: (
    //     <HiddenCodArticulo
    //       config={configGeneral}
    //       getConfig={configuracionGeneral}
    //     />
    //   ),
    // },
    {
      title: "Columnas, Tabla Artículos",
      icon: <ViewWeekIcon style={styles.icon} />,
      component: (
        <ColsTablaArticulos
          config={configGeneral}
          getConfig={configuracionGeneral}
        />
      ),
    },
    // {
    //   title: "Periodo caja",
    //   icon: <ViewWeekIcon style={styles.icon} />,
    //   component: (
    //     <SwitchCaja config={configGeneral} getConfig={configuracionGeneral} />
    //   ),
    // },
    {
      title: "Tipos de venta",
      icon: <ViewWeekIcon style={styles.icon} />,
      component: (
        <TipoVenta config={configGeneral} getConfig={configuracionGeneral} />
      ),
    },
    {
      title: "Percepciones",
      icon: <AttachMoneyIcon style={styles.icon} />,
      component: (
        <Percepciones config={configGeneral} getConfig={configuracionGeneral} />
      ),
    },
    {
      title: "Medidas de etiquetas",
      icon: <HeightIcon style={styles.icon} />,
      component: (
        <MedidasEtiqueta
          config={configGeneral}
          getConfig={configuracionGeneral}
        />
      ),
    },
    {
      title: "Descripciones de artículos",
      icon: <DescriptionIcon style={styles.icon} />,
      component: (
        <DescripcionesArticulos
          config={configGeneral}
          getConfig={configuracionGeneral}
        />
      ),
    },
    {
      title: "Saltar previsualización de PDFS",
      icon: <SkipNextIcon style={styles.icon} />,
      component: (
        <SaltarPrevisualizacion
          config={configGeneral}
          getConfig={configuracionGeneral}
        />
      ),
    },
    {
      icon: <CommentIcon style={styles.icon} />,
      title: "Leyendas de ventas y remitos",
      component: (
        <LeyendasComprobantes
          config={configGeneral}
          getConfig={configuracionGeneral}
        />
      ),
    },
    {
      icon: <AccountBalanceWalletIcon style={styles.icon} />,
      title: "Configuración cuenta corriente cliente",
      component: (
        <LimitarVentasCCC
          config={configGeneral}
          getConfig={configuracionGeneral}
        />
      ),
    },
    {
      icon: <DescriptionIcon style={styles.icon} />,
      title: "Información FCEM",
      component: (
        <InformacionFCEM
          config={configGeneral}
          getConfig={configuracionGeneral}
        />
      ),
    },
    {
      icon: <AttachMoneyIcon style={styles.icon} />,
      title: "Validación de precios de artículos",
      component: (
        <ValidacionPreciosArticulos
          config={configGeneral}
          getConfig={configuracionGeneral}
        />
      ),
    },
    {
      icon: <ComputerIcon style={styles.icon} />,
      title: "Facturador automático Mercado Libre",
      component: (
        <FacturadorAutomaticoMercadoLibre
          config={configGeneral}
          getConfig={configuracionGeneral}
        />
      ),
    },
    {
      icon: <CategoryIcon style={styles.icon} />,
      title: "Organizar pantalla ventas",
      component: (
        <OrganizacionPantallaVentas
          config={configGeneral}
          getConfig={configuracionGeneral}
        />
      ),
    },
    {
      icon: <CategoryIcon style={styles.icon} />,
      title: "Columna última actualización de artículo",
      component: (
        <FechaUltimaActualizacionArticulo
          config={configGeneral}
          getConfig={configuracionGeneral}
        />
      ),
    },
    {
      title: "Tipos de cliente",
      icon: <ViewWeekIcon style={styles.icon} />,
      component: (
        <TiposCliente config={configGeneral} getConfig={configuracionGeneral} />
      ),
    },
    {
      title: <TitleInfoPresupuestos />,
      icon: <PrintIcon style={styles.icon} />,
      component: (
        <InformacionPresupuestos
          config={configGeneral}
          getConfig={configuracionGeneral}
        />
      ),
    },
    {
      title: "Informacion en comprobantes de Ventas",
      icon: <PrintIcon style={styles.icon} />,
      component: (
        <InformacionComprobantesVentas
          config={configGeneral}
          getConfig={configuracionGeneral}
        />
      ),
    },
    {
      title: "Transporte",
      icon: <LocalShippingIcon style={styles.icon} />,
      component: (
        <Transporte config={configGeneral} getConfig={configuracionGeneral} />
      ),
    },
    {
      title: "Provincia de la sucursal",
      icon: <PublicIcon style={styles.icon} />,
      component: (
        <SeleccionarProvinciaSucursal
          config={configGeneral}
          getConfig={configuracionGeneral}
        />
      ),
    },
    {
      title: "Whatsapp modo de envío",
      icon: <WhatsAppIcon style={styles.icon} />,
      component: (
        <WhatsappDefault
          config={configGeneral}
          getConfig={configuracionGeneral}
        />
      ),
    },
    {
      title: "Clave maestra",
      icon: <LockIcon style={styles.icon} />,
      component: (
        <ClaveMaestra config={configGeneral} getConfig={configuracionGeneral} />
      ),
    },
    {
      title: "Preferencia del Menú Lateral",
      icon: <BuildIcon style={styles.icon} />,
      component: <ConfigSideBar config={configGeneral} />,
    },
  ];
}

const styles = {
  icon: {
    color: "rgb(87, 122, 161)",
  },
};
