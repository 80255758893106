import React from "react";
import { useGeneralConfigStore } from "../../../stores";

export default function NavbarToggle() {
  const { setSidebarOpen } = useGeneralConfigStore();

  return (
    <nav className="nav">
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        onClick={() => setSidebarOpen(true)}
        className="nav-link nav-link-icon toggle-sidebar d-sm-inline d-md-inline d-lg-none text-center"
        style={{ cursor: "pointer" }}
      >
        <i className="material-icons">&#xE5D2;</i>
      </a>
    </nav>
  );
}
