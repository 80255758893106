import { Grid, Switch } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useState } from "react";
import { useGeneralConfigStore } from "../../../../../stores";

export default function ConfigSideBar() {
  const { sidebar_menu_config, updateProperty } = useGeneralConfigStore(
    (state) => ({
      sidebar_menu_config: state.config.sidebar_menu_config,
      updateProperty: state.updateProperty,
    }),
  );

  const [state, setState] = useState(sidebar_menu_config);

  const handleChageReservaStock = async () => {
    setState((prev) => {
      const newState = !prev;
      updateProperty("sidebar_menu_config", newState, true);
      return newState;
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Alert severity="info">
          Configura cómo se comportará el menú lateral (barra de navegación) en
          el sistema. Elegí si preferís que se mantenga fijo o que se repliegue
          automáticamente para aprovechar más espacio en pantalla.
        </Alert>
      </Grid>
      <Grid container item spacing={2} xs={12} alignItems="flex-start">
        <Grid
          item
          xs={4}
          sm={4}
          style={{ textAlign: "right", alignSelf: "center" }}
        >
          Menú Fijo
        </Grid>
        <Grid
          item
          xs={4}
          sm={2}
          style={{ textAlign: "center", alignSelf: "center" }}
        >
          <Switch
            checked={state}
            color="primary"
            onChange={handleChageReservaStock}
            name="configSideBar"
          />
        </Grid>
        <Grid
          item
          xs={4}
          sm={6}
          style={{ textAlign: "left", alignSelf: "center" }}
        >
          Menú Automático
        </Grid>
      </Grid>
    </Grid>
  );
}
